import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import {
  getRecommendKeywords,
  createRecommendKeyword,
  updateRecommendKeywordPriority,
  CreateRecommendKeywordRequestOptions,
  UpdateRecommendKeywordPriorityRequestOptions,
  DeleteRecommendKeywordRequestOptions,
  deleteRecommendKeyword
} from '@/services/api';
import { UseRecommendKeywordsOptions } from './interfaces';

export function useRecommendKeywords({ sort, sortKey }: UseRecommendKeywordsOptions = {}) {
  return useQuery(
    reactive(['recommend-keywords', { sort, sortKey }]),
    () => getRecommendKeywords({
      query: {
        sort: unref(sort),
        sortKey: unref(sortKey)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateRecommendKeyword() {
  return useMutation((options: CreateRecommendKeywordRequestOptions) => createRecommendKeyword(options));
}

export function useUpdateRecommendKeywordPriority() {
  return useMutation((options: UpdateRecommendKeywordPriorityRequestOptions) => updateRecommendKeywordPriority(options));
}

export function useDeleteRecommendKeyword() {
  return useMutation((options: DeleteRecommendKeywordRequestOptions) => deleteRecommendKeyword(options));
}
