import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getChannelAgent,
  getChannelAgents,
  updateChannelAgent,
  GetChannelAgentRequestOptions,
  UpdateChannelAgentRequestOptions,
  ChannelAgentResponse,
  CreateChannelAgentRequestOptions,
  createChannelAgent,
  deleteChannelAgent,
  DeleteChannelAgentRequestOptions
} from '@/services/api';
import { UseChannelAgentsOptions } from './interfaces';

export function useChannelAgent(options: GetChannelAgentRequestOptions, useOptions: Omit<UseQueryOptions<ChannelAgentResponse, unknown, ChannelAgentResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('channel-agent', () => getChannelAgent(options), useOptions);
}

export function useChannelAgents({ page, keyword, pageSize }: UseChannelAgentsOptions = {}) {
  return useQuery(
    reactive(['channel-agents', { page, keyword, pageSize }]),
    () => getChannelAgents({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize) } }),
    { keepPreviousData: true }
  );
}

export function useCreateChannelAgent() {
  return useMutation((options: CreateChannelAgentRequestOptions) => createChannelAgent(options));
}

export function useUpdateChannelAgent() {
  return useMutation((options: UpdateChannelAgentRequestOptions) => updateChannelAgent(options));
}

export function useDeleteChannelAgent() {
  return useMutation((options: DeleteChannelAgentRequestOptions) => deleteChannelAgent(options));
}
