import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getCoupon,
  getCoupons,
  updateCoupon,
  GetCouponRequestOptions,
  UpdateCouponRequestOptions,
  CouponResponse,
  CreateCouponRequestOptions,
  createCoupon,
  deleteCoupon,
  DeleteCouponRequestOptions,
  takeCouponDown,
  TakeCouponDownRequestOptions
} from '@/services/api';
import { UseCouponsOptions } from './interfaces';

export function useCoupon(options: GetCouponRequestOptions, useOptions: Omit<UseQueryOptions<CouponResponse, unknown, CouponResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('coupon', () => getCoupon(options), useOptions);
}

export function useCoupons({ page, keyword, pageSize }: UseCouponsOptions = {}) {
  return useQuery(
    reactive(['coupons', { page, keyword, pageSize }]),
    () => getCoupons({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize) } }),
    { keepPreviousData: true }
  );
}

export function useCreateCoupon() {
  return useMutation((options: CreateCouponRequestOptions) => createCoupon(options));
}

export function useUpdateCoupon() {
  return useMutation((options: UpdateCouponRequestOptions) => updateCoupon(options));
}

export function useDeleteCoupon() {
  return useMutation((options: DeleteCouponRequestOptions) => deleteCoupon(options));
}

export function useExpireCoupon() {
  return useMutation((options: TakeCouponDownRequestOptions) => takeCouponDown(options));
}
