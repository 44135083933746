import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getRole,
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  GetRoleRequestOptions,
  CreateRoleRequestOptions,
  UpdateRoleRequestOptions,
  DeleteRoleRequestOptions,
  RoleResponse
} from '@/services/api';
import { UseRolesOptions } from './interfaces';

export function useRole(options: GetRoleRequestOptions, useOptions: Omit<UseQueryOptions<RoleResponse, unknown, RoleResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('role', () => getRole(options), useOptions);
}

export function useRoles({ page, pageSize }: UseRolesOptions = {}) {
  return useQuery(
    reactive(['roles', { page, pageSize }]),
    () => getRoles({ query: { page: unref(page), pageSize: unref(pageSize) } }),
    { keepPreviousData: true }
  );
}

export function useCreateRole() {
  return useMutation((options: CreateRoleRequestOptions) => createRole(options));
}

export function useUpdateRole() {
  return useMutation((options: UpdateRoleRequestOptions) => updateRole(options));
}

export function useDeleteRole() {
  return useMutation((options: DeleteRoleRequestOptions) => deleteRole(options));
}
