import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getUser,
  getUsers,
  updateUser,
  GetUserRequestOptions,
  UpdateUserRequestOptions,
  UserResponse,
  CreateUserRequestOptions,
  createUser,
  deleteUser,
  DeleteUserRequestOptions,
  getUserWteStatistics,
  exportUsers,
  getWatchHistories
} from '@/services/api';
import { UseExportUsersOptions, UseUsersOptions, MaybeRef } from './interfaces';

export function useUser(options: GetUserRequestOptions, useOptions: Omit<UseQueryOptions<UserResponse, unknown, UserResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('user', () => getUser(options), useOptions);
}

export function useUsers(useUsersOptions: UseUsersOptions = {}) {
  return useQuery(
    reactive(['users', useUsersOptions]),
    () => getUsers({
      query: Object.fromEntries(
        Object
          .entries(useUsersOptions)
          .map(([key, value]) => [key, unref(value)])
      )
    }),
    { keepPreviousData: true }
  );
}

export function useCreateUser() {
  return useMutation((options: CreateUserRequestOptions) => createUser(options));
}

export function useUpdateUser() {
  return useMutation((options: UpdateUserRequestOptions) => updateUser(options));
}

export function useDeleteUser() {
  return useMutation((options: DeleteUserRequestOptions) => deleteUser(options));
}

export function useWatchHistories({ memberNo }: { memberNo: MaybeRef<string> }) {
  return useQuery(
    reactive(['memberNo', { memberNo }]),
    () => getWatchHistories({ query: { memberNo: unref(memberNo) } }),
    { keepPreviousData: true }
  );
}
