import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import {
  getEventsSuggestion,
  getSuggestionsCount,
  getSuggestions,
  CreateSuggestionRequestOptions,
  createSuggestion,
  ChangeStatusRequestOptions,
  deleteSuggestion,
  exclude,
  include,
  verifying,
  ExcludeAllRequestOptions,
  excludeAll
} from '@/services/api';
import { UseEventsSuggestionOptions, UseSuggestionOptions } from './interfaces';

export function useSuggestionCount({ eventId }, { keyword }: UseSuggestionOptions = {}) {
  return useQuery(
    reactive(['suggestionCount', { keyword }]),
    () => getSuggestionsCount(
      { eventId }, {
        query: {
          keyword: unref(keyword)
        }
      }),
    {
      keepPreviousData: true
    }
  );
}

export function useEventsSuggestion({ page, keyword, pageSize, eventId, eventStartedAt, eventEndedAt, status }: UseEventsSuggestionOptions = {}) {
  return useQuery(
    reactive(['eventsSuggestion', { page, keyword, pageSize, eventId, eventStartedAt, eventEndedAt, status }]),
    () => getEventsSuggestion({
      query: {
        page: unref(page),
        keyword: unref(keyword),
        pageSize: unref(pageSize),
        eventId: unref(eventId),
        status: unref(status),
        eventStartedAt: unref(eventStartedAt),
        eventEndedAt: unref(eventEndedAt)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useSuggestions({ eventId }, { page, pageSize, keyword, type, status }: UseSuggestionOptions = {}) {
  return useQuery(
    reactive(['suggestions', { page, pageSize, keyword, type, status }]),
    () => getSuggestions(
      { eventId }, {
        query: {
          page: unref(page),
          pageSize: unref(pageSize),
          keyword: unref(keyword),
          type: unref(type),
          status: unref(status)
        }
      }),
    {
      keepPreviousData: true
    }
  );
}

export function useCreateSuggestion() {
  return useMutation((options: CreateSuggestionRequestOptions) => createSuggestion(options));
}

export function useDeleteSuggestion() {
  return useMutation((options: ChangeStatusRequestOptions) => deleteSuggestion(options));
}

export function useExclude() {
  return useMutation((options: ChangeStatusRequestOptions) => exclude(options));
}
export function useInclude() {
  return useMutation((options: ChangeStatusRequestOptions) => include(options));
}
export function useVerifying() {
  return useMutation((options: ChangeStatusRequestOptions) => verifying(options));
}
export function useExcludeAll() {
  return useMutation((options: ExcludeAllRequestOptions) => excludeAll(options));
}
