import { reactive, unref } from 'vue';
import { useQuery, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getChannelStatistics,
  getDailyCampaignStatistics,
  getChannelCouponsStatistics,
  getChannelCouponStatistics,
  ChannelCouponStatisticsResponse,
  ChannelCouponsStatisticsResponse,
  getChannelAgentWithdrawalStatistics
} from '@/services/api';
import { UseChannelStatisticsOptions, UseDailyCampaignOptions, UseChannelCouponsOptions, UseChannelAgentWithdrawalStatisticsOptions } from './interfaces';

export function useChannelCoupons(
  { page, pageSize, startDate, endDate }: UseChannelCouponsOptions = {},
  useOptions: Omit<UseQueryOptions<ChannelCouponsStatisticsResponse, unknown, ChannelCouponsStatisticsResponse, QueryKey>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    reactive(['channelCoupons', { page, pageSize, startDate, endDate }]),
    () => getChannelCouponsStatistics({ query: { page: unref(page), pageSize: unref(pageSize), startDate: unref(startDate), endDate: unref(endDate) } }),
    { keepPreviousData: true, ...useOptions }
  );
}

export function useChannelCoupon(
  { channelId, couponId }:
  {
    channelId: number
    couponId: number
  },
  { page, pageSize, startDate, endDate }: UseChannelCouponsOptions = {},
  useOptions: Omit<UseQueryOptions<ChannelCouponStatisticsResponse, unknown, ChannelCouponStatisticsResponse, QueryKey>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    reactive(['channelCoupon', { page, pageSize, startDate, endDate }]),
    () => getChannelCouponStatistics(
      { channelId, couponId },
      { query: { page: unref(page), pageSize: unref(pageSize), startDate: unref(startDate), endDate: unref(endDate) } }
    ),
    { keepPreviousData: true, ...useOptions }
  );
}

export function useDailyCampaign({ page, pageSize, startDate, endDate, channelIds, sortKey, sort, keyword }: UseDailyCampaignOptions = {}) {
  return useQuery(
    reactive(['dailyCampaign', { page, pageSize, startDate, endDate, channelIds, sortKey, sort, keyword }]),
    () => getDailyCampaignStatistics({ query: { page: unref(page), pageSize: unref(pageSize), startDate: unref(startDate), endDate: unref(endDate), channelIds: unref(channelIds), sortKey: unref(sortKey), sort: unref(sort), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useChannelStatistics({ startDate, endDate, channelIds, keyword }: UseChannelStatisticsOptions = {}) {
  return useQuery(
    reactive(['channelStatistics', { startDate, endDate, channelIds, keyword }]),
    () => getChannelStatistics({
      query: {
        startDate: unref(startDate),
        endDate: unref(endDate),
        channelIds: unref(channelIds),
        keyword: unref(keyword)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useChannelAgentWithdrawalStatistics({ startDate, endDate, keyword, status }: UseChannelAgentWithdrawalStatisticsOptions = {}) {
  return useQuery(
    reactive(['channel-agent-withdrawal-statistics', { startDate, endDate, keyword, status }]),
    () => getChannelAgentWithdrawalStatistics({ query: { startDate: unref(startDate), endDate: unref(endDate), keyword: unref(keyword), status: unref(status) } }),
    { keepPreviousData: true }
  );
}
