import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getWork,
  getWorks,
  createWork,
  updateWork,
  deleteWork,
  GetWorkRequestOptions,
  CreateWorkRequestOptions,
  UpdateWorkRequestOptions,
  DeleteWorkRequestOptions,
  WorkResponse,
  TakeWorkDownRequestOptions,
  takeWorkDown,
  PublishWorkRequestOptions,
  publishWork
} from '@/services/api';
import { UseWorksOptions } from './interfaces';

export function useWork(options: GetWorkRequestOptions, useOptions: Omit<UseQueryOptions<WorkResponse, unknown, WorkResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('work', () => getWork(options), useOptions);
}

export function useWorks({ page, keyword, pageSize, isSeries, noExpose, status, agentId, genreId, tagId, isChoose, topTopicId, classificationIds, lang }: UseWorksOptions = {}) {
  return useQuery(
    reactive(['works', { page, keyword, pageSize, isSeries, noExpose, status, agentId, genreId, tagId, isChoose, topTopicId, classificationIds, lang }]),
    () => getWorks({
      query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), isSeries: unref(isSeries), noExpose: unref(noExpose), status: unref(status), agentId: unref(agentId), genreId: unref(genreId), tagId: unref(tagId), isChoose: unref(isChoose), topTopicId: unref(topTopicId), classificationIds: unref(classificationIds), lang: unref(lang) }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateWork() {
  return useMutation((options: CreateWorkRequestOptions) => createWork(options));
}

export function useUpdateWork() {
  return useMutation((options: UpdateWorkRequestOptions) => updateWork(options));
}

export function useDeleteWork() {
  return useMutation((options: DeleteWorkRequestOptions) => deleteWork(options));
}

export function useTakeWorkDown() {
  return useMutation((options: TakeWorkDownRequestOptions) => takeWorkDown(options));
}

export function usePublishWork() {
  return useMutation((options: PublishWorkRequestOptions) => publishWork(options));
}
