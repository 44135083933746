import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import { UseEventsEnrollmentsptions, UseEventsOptions, UseEventsStatisticsOptions } from './interfaces';
import { CreateEventRequestOptions, DeleteEventRequestOptions, IdOption, UpdateEventRequestOptions, createEvent, deleteEvent, getEnrollments, getEvent, getEvents, getStatistics, updateEvent } from '@/services/api';

export function useEvents({ page, keyword, pageSize, startedAt, endedAt, status }: UseEventsOptions = {}) {
  return useQuery(
    reactive(['events', { page, keyword, pageSize, startedAt, endedAt, status }]),
    () => getEvents({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), startedAt: unref(startedAt), endedAt: unref(endedAt), status: unref(status) } }),
    { keepPreviousData: true }
  );
}

export function useEvent({ id }: { id: number | string }) {
  return useQuery(
    reactive(['event', { id }]),
    () => getEvent({ id }),
    { keepPreviousData: true, enabled: !!id }
  );
}

export function useEventsStatistics({ page, pageSize, startDate, endDate }: UseEventsStatisticsOptions = {}, { id }: IdOption) {
  return useQuery(
    reactive(['events', { page, pageSize, startDate, endDate }]),
    () => getStatistics({ query: { page: unref(page), pageSize: unref(pageSize), startDate: unref(startDate), endDate: unref(endDate) }, id: unref(id) }),
    { keepPreviousData: true }
  );
}

export function useEnrollments({ page, pageSize, keyword, startedAt, endedAt, verifiedStatus, isCouponMember, isNFTMember, isBlack, isContactMailVerified }: UseEventsEnrollmentsptions = {}, { id }: IdOption) {
  return useQuery(
    reactive(['events', { page, pageSize, startedAt, endedAt, verifiedStatus, isCouponMember, isNFTMember, isBlack, isContactMailVerified, keyword }]),
    () => getEnrollments({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword), startedAt: unref(startedAt), endedAt: unref(endedAt), verifiedStatus: unref(verifiedStatus), isCouponMember: unref(isCouponMember), isNFTMember: unref(isNFTMember), isBlack: unref(isBlack), isContactMailVerified: unref(isContactMailVerified) }, id: unref(id) }),
    { keepPreviousData: true }
  );
}

export function useCreateEvent() {
  return useMutation((options: CreateEventRequestOptions) => createEvent(options));
}

export function useUpdateEvent() {
  return useMutation((options: UpdateEventRequestOptions) => updateEvent(options));
}

export function useDeleteEvent() {
  return useMutation((options: DeleteEventRequestOptions) => deleteEvent(options));
}
