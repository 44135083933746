import { ComputedRef, reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getWteEventList,
  getWteEvent,
  getEventStatistics,
  getEventUserStatistics
} from '@/services/api';
import { UseEventUserStatisticsOptions, UseEventStatisticsOptions, UseWteEventListOptions } from './interfaces';

export function useWteEventList({ page, pageSize, eventStartedAt, eventEndedAt, status, eventId }: UseWteEventListOptions = {}) {
  return useQuery(
    reactive(['wteEventList', { page, pageSize, eventStartedAt, eventEndedAt, status, eventId }]),
    () => getWteEventList({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        eventStartedAt: unref(eventStartedAt),
        eventEndedAt: unref(eventEndedAt),
        status: unref(status),
        eventId: unref(eventId)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useWteEvent({ eventId }: { eventId: string }) {
  return useQuery(
    reactive(['wteEvent', { eventId }]),
    () => getWteEvent({ eventId: unref(eventId) }),
    { keepPreviousData: true }
  );
}

export function useEventStatistics({ page, pageSize, startDate, endDate, searchEventId }: UseEventStatisticsOptions) {
  return useQuery(
    reactive(['eventDatas', { page, pageSize, startDate, endDate, searchEventId }]),
    () => getEventStatistics({ query: { page: unref(page), pageSize: unref(pageSize), startDate: unref(startDate), endDate: unref(endDate), keyword: unref(searchEventId) } }),
    { keepPreviousData: false, refetchOnWindowFocus: false }
  );
}

export function useEventUserStatistics({ eventId, userType, memberNo, page, pageSize }: UseEventUserStatisticsOptions, enabled: ComputedRef<boolean>) {
  return useQuery(
    reactive(['eventDataDetail', { eventId, userType, memberNo, page, pageSize }]),
    () => getEventUserStatistics({ query: { wteEventId: unref(eventId), userType: unref(userType), memberNo: unref(memberNo), page: unref(page), pageSize: unref(pageSize) } }),
    { keepPreviousData: false, refetchOnWindowFocus: false, enabled }
  );
}
