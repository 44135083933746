import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
// import { QueryKey } from 'vue-query/types';
import { getOperationLogs } from '@/services/api';
import { UseOperationLogsOptions } from './interfaces';

export function useOperationLogs({ page, keyword, pageSize, startDate, endDate, resource, action }: UseOperationLogsOptions = {}) {
  return useQuery(
    reactive(['operationLogs', { page, keyword, pageSize, startDate, endDate, resource, action }]),
    () => getOperationLogs({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), startDate: unref(startDate), endDate: unref(endDate), resource: unref(resource), action: unref(action) } }),
    { keepPreviousData: true }
  );
}
