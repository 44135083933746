import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import {
  getUsersReview,
  UpdateUserReviewRequestOptions,
  updateUserReview
} from '@/services/api';
import { UseUsersViewOptions } from './interfaces';

// TODO: need to rename this file

export function useUsersReview({ page, pageSize, keyword, verifiedType }: UseUsersViewOptions = {}) {
  return useQuery(
    reactive(['users', { page, pageSize, keyword, verifiedType }]),
    () => getUsersReview({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword), verifiedType: unref(verifiedType) } }),
    { keepPreviousData: true }
  );
}

export function useUpdateUserReview() {
  return useMutation((options: UpdateUserReviewRequestOptions) => updateUserReview(options));
}
