import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getChannel,
  getChannels,
  createChannel,
  updateChannel,
  GetChannelRequestOptions,
  CreateChannelRequestOptions,
  UpdateChannelRequestOptions,
  DeleteChannelRequestOptions,
  deleteChannel,
  ChannelResponse,
  getChannelAgentWithdrawals,
  ApproveChannelAgentWithdrawalRequestOptions,
  approveChannelAgentWithdrawal,
  TransferChannelAgentWithdrawalSuccessRequestOptions,
  TransferChannelAgentWithdrawalFailedRequestOptions,
  transferChannelAgentWithdrawalSuccess,
  transferChannelAgentWithdrawalFailed
} from '@/services/api';
import { UseChannelAgentWithdrawalsOptions, UseChannelsOptions } from './interfaces';

export function useChannel(options: GetChannelRequestOptions, useOptions: Omit<UseQueryOptions<ChannelResponse, unknown, ChannelResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('channel', () => getChannel(options), useOptions);
}

export function useChannels({ page, pageSize, keyword, channelAgentId }: UseChannelsOptions = {}) {
  return useQuery(
    reactive(['channels', { page, pageSize, keyword, channelAgentId }]),
    () => getChannels({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword), channelAgentId: unref(channelAgentId) } }),
    { keepPreviousData: true }
  );
}

export function useCreateChannel() {
  return useMutation((options: CreateChannelRequestOptions) => createChannel(options));
}

export function useUpdateChannel() {
  return useMutation((options: UpdateChannelRequestOptions) => updateChannel(options));
}

export function useDeleteChannel() {
  return useMutation((options: DeleteChannelRequestOptions) => deleteChannel(options));
}

export function useChannelAgentWithdrawals({ page, pageSize, keyword, sort, sortKey, startDate, endDate, status }: UseChannelAgentWithdrawalsOptions = {}) {
  return useQuery(
    reactive(['channel-agent-withdrawals', { page, pageSize, keyword, sort, sortKey, startDate, endDate, status }]),
    () => getChannelAgentWithdrawals({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword), status: unref(status), startDate: unref(startDate), endDate: unref(endDate), sort: unref(sort), sortKey: unref(sortKey) } }),
    { keepPreviousData: true }
  );
}

export function useApproveChannelAgentWithdrawal() {
  return useMutation((options: ApproveChannelAgentWithdrawalRequestOptions) => approveChannelAgentWithdrawal(options));
}

export function useTransferChannelAgentWithdrawalSuccess() {
  return useMutation((options: TransferChannelAgentWithdrawalSuccessRequestOptions) => transferChannelAgentWithdrawalSuccess(options));
}

export function useTransferChannelAgentWithdrawalFailed() {
  return useMutation((options: TransferChannelAgentWithdrawalFailedRequestOptions) => transferChannelAgentWithdrawalFailed(options));
}
