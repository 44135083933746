import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getPlan,
  getPlans,
  // createPlan,
  updatePlan,
  GetPlanRequestOptions,
  // CreatePlanRequestOptions,
  UpdatePlanRequestOptions,
  PlanResponse
} from '@/services/api';
import { UsePlansOptions } from './interfaces';

export function usePlan(options: GetPlanRequestOptions, useOptions: Omit<UseQueryOptions<PlanResponse, unknown, PlanResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('plan', () => getPlan(options), useOptions);
}

export function usePlans({ page, keyword, pageSize }: UsePlansOptions = {}) {
  return useQuery(
    reactive(['plans', { page, keyword, pageSize }]),
    () => getPlans({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize) } }),
    { keepPreviousData: true }
  );
}

// export function useCreatePlan() {
//   return useMutation((options: CreatePlanRequestOptions) => createPlan(options));
// }

export function useUpdatePlan() {
  return useMutation((options: UpdatePlanRequestOptions) => updatePlan(options));
}
