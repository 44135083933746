import { reactive, unref } from 'vue';
import { useQuery } from 'vue-query';

import {
  getHotKeywordStatistics,
  getKeywordStatistics,
  getOrganicKeywordStatistics,
  getRecommendedKeywordStatistics,
  getWorkStatistics
} from '@/services/api';
import { UseHotKeywordStatisticsOptions, UseKeywordStatisticsOptions, UseOrganicKeywordStatisticsOptions, UseRecommendedKeywordStatisticsOptions, UseWorkStatisticsOptions } from './interfaces';

export function useKeywordStatistics({ page, pageSize, startDate, endDate, sort, sortKey }: UseKeywordStatisticsOptions = {}) {
  return useQuery(
    reactive(['keyword-statistic', { page, pageSize, startDate, endDate, sort, sortKey }]),
    () => getKeywordStatistics({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        startDate: unref(startDate),
        endDate: unref(endDate),
        sort: unref(sort),
        sortKey: unref(sortKey)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useHotKeywordStatistics({ page, pageSize, startDate, endDate, sort, sortKey, keyword }: UseHotKeywordStatisticsOptions = {}) {
  return useQuery(
    reactive(['hot-keyword-statistic', { page, pageSize, startDate, endDate, sort, sortKey, keyword }]),
    () => getHotKeywordStatistics({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        startDate: unref(startDate),
        endDate: unref(endDate),
        sort: unref(sort),
        sortKey: unref(sortKey),
        keyword: unref(keyword)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useRecommendedKeywordStatistics({ page, pageSize, startDate, endDate, sort, sortKey, keyword }: UseRecommendedKeywordStatisticsOptions = {}) {
  return useQuery(
    reactive(['recommended-keyword-statistic', { page, pageSize, startDate, endDate, sort, sortKey, keyword }]),
    () => getRecommendedKeywordStatistics({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        startDate: unref(startDate),
        endDate: unref(endDate),
        sort: unref(sort),
        sortKey: unref(sortKey),
        keyword: unref(keyword)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useOrganicKeywordStatistics({ page, pageSize, startDate, endDate, sort, sortKey, keyword }: UseOrganicKeywordStatisticsOptions = {}) {
  return useQuery(
    reactive(['organic-keyword-statistic', { page, pageSize, startDate, endDate, sort, sortKey, keyword }]),
    () => getOrganicKeywordStatistics({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        startDate: unref(startDate),
        endDate: unref(endDate),
        sort: unref(sort),
        sortKey: unref(sortKey),
        keyword: unref(keyword)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useWorkStatistics({ page, pageSize, startDate, endDate, sort, sortKey, keyword, agentId, classificationId, actorId, genreId, tagId, status }: UseWorkStatisticsOptions = {}) {
  return useQuery(
    reactive(['work-statistic', { page, pageSize, startDate, endDate, sort, sortKey, keyword, agentId, classificationId, actorId, genreId, tagId, status }]),
    () => getWorkStatistics({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        startDate: unref(startDate),
        endDate: unref(endDate),
        sort: unref(sort),
        sortKey: unref(sortKey),
        keyword: unref(keyword),
        agentId: unref(agentId),
        classificationId: unref(classificationId),
        actorId: unref(actorId),
        genreId: unref(genreId),
        tagId: unref(tagId),
        status: unref(status)
      }
    }),
    { keepPreviousData: true }
  );
}
