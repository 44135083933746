import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getCampaign,
  getCampaigns,
  getCampaignsDaily,
  createCampaign,
  GetCampaignRequestOptions,
  CampaignResponse,
  CreateCampaignRequestOptions,
  UpdateCampaignRequestOptions,
  updateCampaign,
  DeleteCampaignRequestOptions,
  deleteCampaign,
  GetCampaignCommissionRequestOptions,
  CampaignCommissionResponse,
  getCampaignCommission,
  UpdateCampaignCommissionRequestOptions,
  updateCampaignCommission,
  getCpaStatistics
} from '@/services/api';
import {
  UseCampaignsOptions, BaseOptions,
  UseCpaStatisticsOptions
} from './interfaces';

export function useCampaign(options: GetCampaignRequestOptions, useOptions: Omit<UseQueryOptions<CampaignResponse, unknown, CampaignResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('campaign', () => getCampaign(options), useOptions);
}

export function useCampaigns({ page, pageSize, keyword, channelAgentId, sortKey, sort }: UseCampaignsOptions = {}) {
  return useQuery(
    reactive(['campaigns', { page, pageSize, keyword, channelAgentId, sortKey, sort }]),
    () => getCampaigns({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword), channelAgentId: unref(channelAgentId), sortKey: unref(sortKey), sort: unref(sort) } }),
    { keepPreviousData: true }
  );
}

export function useCpaStatistics({ page, pageSize, id, startedAt, endedAt }: UseCpaStatisticsOptions = {}) {
  return useQuery(
    reactive(['campaigns', { page, pageSize, id, startedAt, endedAt }]),
    () => getCpaStatistics({ id: unref(id) }, { query: { page: unref(page), pageSize: unref(pageSize), startedAt: unref(startedAt), endedAt: unref(endedAt) } }),
    { keepPreviousData: true }
  );
}

export function useCampaignsDaily({ campaignId }, { page, pageSize }: BaseOptions = {}) {
  return useQuery(
    reactive(['campaignsDaily', { page, pageSize }]),
    () => getCampaignsDaily(
      { campaignId },
      { query: { page: unref(page), pageSize: unref(pageSize) } }
    ),
    { keepPreviousData: true }
  );
}

export function useCreateCampaign() {
  return useMutation((options: CreateCampaignRequestOptions) => createCampaign(options));
}

export function useUpdateCampaign() {
  return useMutation((options: UpdateCampaignRequestOptions) => updateCampaign(options));
}

export function useDeleteCampaign() {
  return useMutation((options: DeleteCampaignRequestOptions) => deleteCampaign(options));
}

export function useCampaignCommission(options: GetCampaignCommissionRequestOptions, useOptions: Omit<UseQueryOptions<CampaignCommissionResponse, unknown, CampaignCommissionResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('campaign-commission', () => getCampaignCommission(options), useOptions);
}

export function useUpdateCampaignCommission() {
  return useMutation((options: UpdateCampaignCommissionRequestOptions) => updateCampaignCommission(options));
}
