import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { getShowExposes, getShowExpose, createShowExposes, CreateShowExposesRequestOptions, updateShowExposes, UpdateShowExposesRequestOptions, deleteShowExposes, ShowExposeResponse } from '@/services/api';
import { UseShowExposesOptions } from './interfaces';
import { QueryKey } from 'vue-query/types';

// 取得全部list資料
export function useShowExposes({ page, pageSize, status, sort, sortKey }: UseShowExposesOptions = {}) {
  return useQuery(
    reactive(['exposes', { page, pageSize, status, sort, sortKey }]),
    () => getShowExposes({ query: { page: unref(page), pageSize: unref(pageSize), status: unref(status), sort: unref(sort), sortKey: unref(sortKey) } }),
    { keepPreviousData: true }
  );
}

// 取得單個資料
export function useShowExpose(id: string, useOptions: Omit<UseQueryOptions<ShowExposeResponse, unknown, ShowExposeResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    reactive(['expose', id]),
    () => getShowExpose(id),
    useOptions
  );
}

export function useCreateShowExposes() {
  return useMutation((options: CreateShowExposesRequestOptions) => createShowExposes(options));
}

export function useUpdateShowExposes() {
  return useMutation((options: UpdateShowExposesRequestOptions) => updateShowExposes(options));
}

export function useDeleteShowExposes() {
  return useMutation((id: number) => deleteShowExposes(id));
}
