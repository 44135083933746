import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getAdvertisement,
  getAdvertisements,
  updateAdvertisement,
  GetAdvertisementRequestOptions,
  UpdateAdvertisementRequestOptions,
  CreateAdvertisementRequestOptions,
  createAdvertisement,
  deleteAdvertisement,
  DeleteAdvertisementRequestOptions,
  AdvertisementResponse,
  getAdvertisementsStatistics
} from '@/services/api';
import { UseAdvertisementsOptions, UseAdvertisementsStatisticsOptions } from './interfaces';

export function useAdvertisement(options: GetAdvertisementRequestOptions, useOptions: Omit<UseQueryOptions<AdvertisementResponse, unknown, AdvertisementResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('advertisement', () => getAdvertisement(options), useOptions);
}

export function useAdvertisements({ page, keyword, pageSize, position, blockIds, status, sort }: UseAdvertisementsOptions = {}) {
  return useQuery(
    reactive(['advertisements', { page, keyword, pageSize, position, blockIds, status, sort }]),
    () => getAdvertisements({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), position: unref(position), blockIds: unref(blockIds), status: unref(status), sort: unref(sort) } }),
    { keepPreviousData: true }
  );
}

export function useCreateAdvertisement() {
  return useMutation((options: CreateAdvertisementRequestOptions) => createAdvertisement(options));
}

export function useUpdateAdvertisement() {
  return useMutation((options: UpdateAdvertisementRequestOptions) => updateAdvertisement(options));
}

export function useDeleteAdvertisement() {
  return useMutation((options: DeleteAdvertisementRequestOptions) => deleteAdvertisement(options));
}

export function useAdvertisementsStatistics({ page, keyword, pageSize, position, blockIds, status, sort, startedAt, endedAt }: UseAdvertisementsStatisticsOptions = {}) {
  return useQuery(
    reactive(['advertisementsStatistics', { page, keyword, pageSize, position, blockIds, status, sort, startedAt, endedAt }]),
    () => getAdvertisementsStatistics({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), position: unref(position), blockIds: unref(blockIds), status: unref(status), sort: unref(sort), startedAt: unref(startedAt), endedAt: unref(endedAt) } }),
    { keepPreviousData: true }
  );
}
