import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import { getBlocks, getBlock } from '@/services/api';
import { UseBlocksOptions } from './interfaces';

export function useBlocks() {
  return useQuery(
    reactive(['blocks']),
    () => getBlocks()
  );
}

export function useBlock(id: number) {
  return useQuery(
    reactive(['block']),
    () => getBlock(id)
  );
}
