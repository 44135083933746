import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import {
  getHotKeywords,
  createHotKeyword,
  updateHotKeywordRank,
  CreateHotKeywordRequestOptions,
  UpdateHotKeywordRankRequestOptions,
  DeleteHotKeywordRequestOptions,
  deleteHotKeyword
} from '@/services/api';
import { UseHotKeywordsOptions } from './interfaces';

export function useHotKeywords({ sort, sortKey }: UseHotKeywordsOptions = {}) {
  return useQuery(
    reactive(['hot-keywords', { sort, sortKey }]),
    () => getHotKeywords({
      query: {
        sort: unref(sort),
        sortKey: unref(sortKey)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateHotKeyword() {
  return useMutation((options: CreateHotKeywordRequestOptions) => createHotKeyword(options));
}

export function useUpdateHotKeywordRank() {
  return useMutation((options: UpdateHotKeywordRankRequestOptions) => updateHotKeywordRank(options));
}

export function useDeleteHotKeyword() {
  return useMutation((options: DeleteHotKeywordRequestOptions) => deleteHotKeyword(options));
}
