import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  // getAgent,
  getAgents
  // createAgent,
  // updateAgent,
  // deleteAgent,
  // enableAgent,
  // disableAgent,
  // GetAgentRequestOptions,
  // CreateAgentRequestOptions,
  // UpdateAgentRequestOptions,
  // DeleteAgentRequestOptions,
  // ResetAgentRequestOptions,
  // resetQRCodeAgent,
  // DisableAgentRequestOptions,
  // EnableAgentRequestOptions,
  // AgentResponse
} from '@/services/api';
import { UseAgentsOptions } from './interfaces';

// export function useAgent(options: GetAgentRequestOptions, useOptions: Omit<UseQueryOptions<AgentResponse, unknown, AgentResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
//   return useQuery('agent', () => getAgent(options), useOptions);
// }

export function useAgents({ page, pageSize, keyword }: UseAgentsOptions = {}) {
  return useQuery(
    reactive(['agents', { page, pageSize, keyword }]),
    () => getAgents({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

// export function useCreateAgent() {
//   return useMutation((options: CreateAgentRequestOptions) => createAgent(options));
// }

// export function useUpdateAgent() {
//   return useMutation((options: UpdateAgentRequestOptions) => updateAgent(options));
// }

// export function useDeleteAgent() {
//   return useMutation((options: DeleteAgentRequestOptions) => deleteAgent(options));
// }

// export function useResetQRCodeAgent() {
//   return useMutation((options: ResetAgentRequestOptions) => resetQRCodeAgent(options));
// }

// export function useEnableAgent() {
//   return useMutation((options: EnableAgentRequestOptions) => enableAgent(options));
// }

// export function useDisableAgent() {
//   return useMutation((options: DisableAgentRequestOptions) => disableAgent(options));
// }
