import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  UpdateRefundRequestOptions,
  getOrders,
  updateRefund
} from '@/services/api';
import { UseOrdersOptions } from './interfaces';

export function useOrders(useOrderOptions: UseOrdersOptions = {}) {
  return useQuery(
    reactive(['orders', useOrderOptions]),
    () => getOrders({
      query: Object.fromEntries(
        Object
          .entries(useOrderOptions)
          .map(([key, value]) => [key, unref(value)])
      )
    }),
    { keepPreviousData: true }
  );
}

export function useUpdateRefund() {
  return useMutation((options: UpdateRefundRequestOptions) => updateRefund(options));
}
