import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getTag,
  getTags,
  createTag,
  updateTag,
  GetTagRequestOptions,
  CreateTagRequestOptions,
  UpdateTagRequestOptions,
  DeleteTagRequestOptions,
  deleteTag,
  TagResponse
} from '@/services/api';
import { UseTagsOptions } from './interfaces';

export function useTag(options: GetTagRequestOptions, useOptions: Omit<UseQueryOptions<TagResponse, unknown, TagResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('tag', () => getTag(options), useOptions);
}

export function useTags({ page, pageSize, lang }: UseTagsOptions = {}) {
  return useQuery(
    reactive(['tags', { page, pageSize, lang }]),
    () => getTags({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        lang: unref(lang)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateTag() {
  return useMutation((options: CreateTagRequestOptions) => createTag(options));
}

export function useUpdateTag() {
  return useMutation((options: UpdateTagRequestOptions) => updateTag(options));
}

export function useDeleteTag() {
  return useMutation((options: DeleteTagRequestOptions) => deleteTag(options));
}
