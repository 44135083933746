import { reactive, unref } from 'vue';
import { useMutation, useQuery } from 'vue-query';

import {
  SubscriptionCancellationOptions,
  getSubscriptionCancellations,
  getSubscriptions,
  subscriptionCancellation
} from '@/services/api';
import { UseSubscriptionCancellationsOptions, UseSubscriptionsOptions } from './interfaces';

export function useSubscriptions({ page, keyword, pageSize, planId, source, startDate, endDate }: UseSubscriptionsOptions = {}) {
  return useQuery(
    reactive(['subscriptions', { page, keyword, pageSize, planId, source, startDate, endDate }]),
    () => getSubscriptions({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), planId: unref(planId), source: unref(source), startDate: unref(startDate), endDate: unref(endDate) } }),
    { keepPreviousData: true }
  );
}
export function useSubscriptionCancellations(
  { page, keyword, pageSize, planId, cancelReason, startedAt, endedAt }:
  UseSubscriptionCancellationsOptions = {}) {
  return useQuery(
    reactive(['subscriptions', { page, keyword, pageSize, planId, cancelReason, startedAt, endedAt }]),
    () => getSubscriptionCancellations({ query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), planId: unref(planId), cancelReason: unref(cancelReason), startedAt: unref(startedAt), endedAt: unref(endedAt) } }),
    { keepPreviousData: true }
  );
}

export function useSubscriptionCancellation() {
  return useMutation((params: SubscriptionCancellationOptions) => subscriptionCancellation(params));
}
