import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getActor,
  getActors,
  createActor,
  updateActor,
  deleteActor,
  GetActorRequestOptions,
  CreateActorRequestOptions,
  UpdateActorRequestOptions,
  DeleteActorRequestOptions,
  ActorResponse
} from '@/services/api';
import { UseActorsOptions } from './interfaces';

export function useActor(options: GetActorRequestOptions, useOptions: Omit<UseQueryOptions<ActorResponse, unknown, ActorResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('actor', () => getActor(options), useOptions);
}

export function useActors({ page, pageSize, lang }: UseActorsOptions = {}) {
  return useQuery(
    reactive(['actors', { page, pageSize, lang }]),
    () => getActors({ query: { page: unref(page), pageSize: unref(pageSize), lang: unref(lang) } }),
    { keepPreviousData: true }
  );
}

export function useCreateActor() {
  return useMutation((options: CreateActorRequestOptions) => createActor(options));
}

export function useUpdateActor() {
  return useMutation((options: UpdateActorRequestOptions) => updateActor(options));
}

export function useDeleteActor() {
  return useMutation((options: DeleteActorRequestOptions) => deleteActor(options));
}
