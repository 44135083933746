import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getAdmin,
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  enableAdmin,
  disableAdmin,
  GetAdminRequestOptions,
  CreateAdminRequestOptions,
  UpdateAdminRequestOptions,
  DeleteAdminRequestOptions,
  ResetAdmin2FARequestOptions,
  resetAdmin2FA,
  DisableAdminRequestOptions,
  EnableAdminRequestOptions,
  AdminResponse
} from '@/services/api';
import { UseAdminsOptions } from './interfaces';

export function useAdmin(options: GetAdminRequestOptions, useOptions: Omit<UseQueryOptions<AdminResponse, unknown, AdminResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('admin', () => getAdmin(options), useOptions);
}

export function useAdmins({ page, pageSize, keyword }: UseAdminsOptions = {}) {
  return useQuery(
    reactive(['admins', { page, pageSize, keyword }]),
    () => getAdmins({ query: { page: unref(page), pageSize: unref(pageSize), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useCreateAdmin() {
  return useMutation((options: CreateAdminRequestOptions) => createAdmin(options));
}

export function useUpdateAdmin() {
  return useMutation((options: UpdateAdminRequestOptions) => updateAdmin(options));
}

export function useDeleteAdmin() {
  return useMutation((options: DeleteAdminRequestOptions) => deleteAdmin(options));
}

export function useResetAdmin2FA() {
  return useMutation((options: ResetAdmin2FARequestOptions) => resetAdmin2FA(options));
}

export function useEnableAdmin() {
  return useMutation((options: EnableAdminRequestOptions) => enableAdmin(options));
}

export function useDisableAdmin() {
  return useMutation((options: DisableAdminRequestOptions) => disableAdmin(options));
}
