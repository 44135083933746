import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getGenres,
  DeleteGenreRequestOptions,
  CreateGenreRequestOptions,
  UpdateGenreRequestOptions,
  GetGenreRequestOptions,
  deleteGenre,
  createGenre,
  updateGenre,
  getGenre,
  GenreResponse
} from '@/services/api';
import { UseGenresOptions } from './interfaces';

export function useGenre(options: GetGenreRequestOptions, useOptions: Omit<UseQueryOptions<GenreResponse, unknown, GenreResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('genre', () => getGenre(options), useOptions);
}

export function useGenres({ page, pageSize, lang }: UseGenresOptions = {}) {
  return useQuery(
    reactive(['genres', { page, pageSize, lang }]),
    () => getGenres({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        lang: unref(lang)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateGenre() {
  return useMutation((options: CreateGenreRequestOptions) => createGenre(options));
}

export function useUpdateGenre() {
  return useMutation((options: UpdateGenreRequestOptions) => updateGenre(options));
}

export function useDeleteGenre() {
  return useMutation((options: DeleteGenreRequestOptions) => deleteGenre(options));
}
