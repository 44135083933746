import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getTopic,
  getTopics,
  updateTopic,
  GetTopicRequestOptions,
  UpdateTopicRequestOptions,
  CreateTopicRequestOptions,
  createTopic,
  deleteTopic,
  DeleteTopicRequestOptions,
  TopicResponse,
  getTopicWorks
} from '@/services/api';
import { UseTopicsOptions, UseTopicWorksOptions } from './interfaces';

export function useTopicWorks({ page, keyword, pageSize, isSeries, noExpose, status, agentId, genreId, tagId, isChoose, topTopicId, classificationIds, topicId }: UseTopicWorksOptions = {}) {
  return useQuery(
    reactive(['topicWorks', { page, keyword, pageSize, isSeries, noExpose, status, agentId, genreId, tagId, isChoose, topTopicId, classificationIds, topicId }]),
    () => getTopicWorks({
      query: { page: unref(page), keyword: unref(keyword), pageSize: unref(pageSize), isSeries: unref(isSeries), noExpose: unref(noExpose), status: unref(status), agentId: unref(agentId), genreId: unref(genreId), tagId: unref(tagId), isChoose: unref(isChoose), topTopicId: unref(topTopicId), classificationIds: unref(classificationIds), topicId: unref(topicId) }
    }),
    { keepPreviousData: true }
  );
}

export function useTopic(options: GetTopicRequestOptions, useOptions: Omit<UseQueryOptions<TopicResponse, unknown, TopicResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('topic', () => getTopic(options), useOptions);
}

export function useTopics({ page, keyword, pageSize, blockId, type, classificationIds, blacklistIds, lang }: UseTopicsOptions = {}) {
  return useQuery(
    reactive(['topics', { page, keyword, pageSize, blockId, type, classificationIds, blacklistIds, lang }]),
    () => getTopics({
      query: {
        page: unref(page),
        keyword: unref(keyword),
        pageSize: unref(pageSize),
        blockId: unref(blockId),
        type: unref(type),
        classificationIds: unref(classificationIds),
        blacklistIds: unref(blacklistIds),
        lang: unref(lang)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateTopic() {
  return useMutation((options: CreateTopicRequestOptions) => createTopic(options));
}

export function useUpdateTopic() {
  return useMutation((options: UpdateTopicRequestOptions) => updateTopic(options));
}

export function useDeleteTopic() {
  return useMutation((options: DeleteTopicRequestOptions) => deleteTopic(options));
}
