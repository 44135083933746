import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getAnnouncements,
  CreateAnnouncementRequestOptions,
  UpdateAnnouncementRequestOptions,
  GetAnnouncementRequestOptions,
  createAnnouncement,
  updateAnnouncement,
  patchAnnouncement,
  getAnnouncement,
  AnnouncementResponse,
  AnnouncementIdOption,
  getAnnouncementSpecifiedUsers
} from '@/services/api';
import { UseAnnouncementsOptions, UseAnnouncementsSpecifiedUsersOptions } from './interfaces';

export function useAnnouncement(options: GetAnnouncementRequestOptions, useOptions: Omit<UseQueryOptions<AnnouncementResponse, unknown, AnnouncementResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('announcement', () => getAnnouncement(options), useOptions);
}

export function useAnnouncements({ page, pageSize, keyword, startedAt, endedAt, type, targetUsers, isEnabled, isTop, status, sort, sortKey, lang }: UseAnnouncementsOptions = {}) {
  return useQuery(
    reactive(['announcements', { page, pageSize, keyword, startedAt, endedAt, type, targetUsers, isEnabled, isTop, status, sort, sortKey, lang }]),
    () => getAnnouncements({
      query: {
        page: unref(page),
        pageSize: unref(pageSize),
        keyword: unref(keyword),
        startedAt: unref(startedAt),
        endedAt: unref(endedAt),
        type: unref(type),
        targetUsers: unref(targetUsers),
        isEnabled: unref(isEnabled),
        isTop: unref(isTop),
        status: unref(status),
        sort: unref(sort),
        sortKey: unref(sortKey),
        lang: unref(lang)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateAnnouncement() {
  return useMutation((options: CreateAnnouncementRequestOptions) => createAnnouncement(options));
}

export function useUpdateAnnouncement() {
  return useMutation((options: UpdateAnnouncementRequestOptions) => updateAnnouncement(options));
}

export function usePatchAnnouncement() {
  return useMutation((options: UpdateAnnouncementRequestOptions) => patchAnnouncement(options));
}

export function useAnnouncementSpecifiedUsers({ announcementId }: AnnouncementIdOption, { page, pageSize }: UseAnnouncementsSpecifiedUsersOptions = {}) {
  return useQuery(
    reactive(['announcement', { page, pageSize }]),
    () => getAnnouncementSpecifiedUsers({
      announcementId,
      query: {
        page: unref(page),
        pageSize: unref(pageSize)
      }
    }),
    { keepPreviousData: true }
  );
}
